import React, { useState } from "react";
import FieldVerifyModal from "../../components/Verification/FieldVerifyModal/FieldVerifyModal";
import "./KycVerification.css";
import Step from "../../components/Verification/FieldVerifyModal/Step";
import CameraField from "../../components/Verification/Camera/CameraField";
import SuccessfullModal from "../../components/Verification/Modal/SuccessfullModal";

const KycVerification = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill("")); // OTP state
  const [isOtpEntered, setIsOtpEntered] = useState(false); // State to switch between OTP and confirmation
  const [fieldToVerify, setFieldToVerify] = useState(""); // Track the field being verified
  const [imageUrl, setImageUrl] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const [utilitySrc, setUtilitySrc] = useState(null);
  const [submit, setSubmit] = useState(false)

  const [verifiedSteps, setVerifiedSteps] = useState({
    email: false,
    bvn: false,
    nin: false,
    phoneNumber: false,
    profileImage: false,
    address: false,
  });

  const handleVerify = (field) => {
    setFieldToVerify(field); // Set the field being verified
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOtpChange = (e, fieldIndex) => {
    if (isNaN(e.target.value)) return false;

    setOtp([
      ...otp.map((data, index) =>
        index === fieldIndex ? e.target.value : data
      ),
    ]);

    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handleConfirmOtp = () => {
    if (otp.every((digit) => digit !== "")) {
      // Move to the confirmation step
      // handleContinue();
      // console.log(otp.join(""))
      setIsOtpEntered(true);
    } else {
      alert("Please enter a valid 4-digit OTP");
    }
  };

  // const handleContinue = () => {
  //   if (isOtpEntered) {
  //     setIsModalOpen(false);

  //     // Mark the current step as verified based on the field being verified
  //     const fieldMap = {
  //       email: "email",
  //       phoneNumber: "phoneNumber",
  //       bvn: "bvn",
  //       nin: "nin",
  //       profileImage: "profileImage",
  //       address: "address",
  //     };

  //     setVerifiedSteps({ ...verifiedSteps, [fieldMap[fieldToVerify]]: true });

  //     // Proceed to the next step
  //     setCurrentStep(currentStep + 1);

  //     // Reset OTP state
  //     setOtp(new Array(4).fill(""));
  //     setIsOtpEntered(false);
  //     setFieldToVerify(""); // Clear the field being verified
  //   }
  // };

  const handleContinue = () => {
    if (fieldToVerify === "profileImage" && isImageCaptured) {
      // Mark profile image as verified
      setVerifiedSteps({ ...verifiedSteps, profileImage: true });
      setOpenCamera(false);
      setIsModalOpen(true); // Open the modal for confirmation
      setIsOtpEntered(true); // Directly move to the verification status step
    } else if (isOtpEntered) {
      setIsModalOpen(false);

      // Mark the current step as verified based on the field being verified
      const fieldMap = {
        email: "email",
        phoneNumber: "phoneNumber",
        bvn: "bvn",
        nin: "nin",
        profileImage: "profileImage",
        address: "address",
        UtilityBill: "utility",
      };

      setVerifiedSteps({ ...verifiedSteps, [fieldMap[fieldToVerify]]: true });

      // Proceed to the next step
      setCurrentStep(currentStep + 1);

      // Reset OTP state
      setOtp(new Array(4).fill(""));
      setIsOtpEntered(false);
      setFieldToVerify(""); // Clear the field being verified
    }
  };

  const handleCapture = (imageSrc) => {
    const imageName = "captured_image.jpg";
    setImageUrl(imageSrc);
    setIsImageCaptured(true);
    setOpenCamera(false);
    setIsModalOpen(true);
    setIsOtpEntered(true);
    setFieldToVerify("ProfileImage");
  };

  const handleUtilityUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUtilitySrc(imageUrl);
    }
  };

  const handleSubmit = () => {
    console.log("hello")
    setSubmit(true)
  }

  return (
    <div className="kyc_verification_main_container">
      <h1>KYC Verification</h1>

      <div className="kyc_fieldstep_container_wrapper">
        <div className="kyc_fieldstep_container">
          <h2>Dollar Card KYC Verification process</h2>

          <div className="step_outer_wrapper">
            <Step currentStep={currentStep} />
          </div>

          <div className="kyc_form_wrapper">
            {currentStep >= 1 && (
              <div className="general_form_field">
                <label>Email:</label>
                <div className="input_button_container">
                  <input
                    type="email"
                    disabled={verifiedSteps.email}
                    placeholder="Patrick001@gmail.com"
                    className={verifiedSteps.email ? "input-disabled" : ""}
                  />
                  <button
                    onClick={() => handleVerify("email")}
                    disabled={verifiedSteps.email}
                    className={verifiedSteps.email ? "button-verified" : ""}
                  >
                    {verifiedSteps.email ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 2 && (
              <div className="general_form_field">
                <label>Phone:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={verifiedSteps.phoneNumber}
                    className={
                      verifiedSteps.phoneNumber ? "input-disabled" : ""
                    }
                  />
                  <button
                    onClick={() => handleVerify("phoneNumber")}
                    disabled={verifiedSteps.phoneNumber}
                    className={
                      verifiedSteps.phoneNumber ? "button-verified" : ""
                    }
                  >
                    {verifiedSteps.phoneNumber ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 3 && (
              <div className="general_form_field">
                <label>BVN:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={verifiedSteps.bvn}
                    className={verifiedSteps.bvn ? "input-disabled" : ""}
                  />
                  <button
                    onClick={() => handleVerify("bvn")}
                    disabled={verifiedSteps.bvn}
                    className={verifiedSteps.bvn ? "button-verified" : ""}
                  >
                    {verifiedSteps.bvn ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 4 && (
              <div className="general_form_field">
                <label>NIN:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={verifiedSteps.nin}
                    className={verifiedSteps.nin ? "input-disabled" : ""}
                  />
                  <button
                    onClick={() => handleVerify("nin")}
                    disabled={verifiedSteps.nin}
                    className={verifiedSteps.nin ? "button-verified" : ""}
                  >
                    {verifiedSteps.nin ? "Verified" : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {currentStep >= 5 && (
              <div className="general_form_field">
                <label>Image:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    id="image"
                    disabled={verifiedSteps.profileImage}
                    readOnly
                    value={isImageCaptured ? "captured_image.jpg" : ""}
                    className={
                      verifiedSteps.profileImage
                        ? "input-disabled small_file_input"
                        : "small_file_input"
                    }
                  />
                  {isImageCaptured ? (
                    <img
                      src={imageUrl}
                      alt="Captured"
                      style={{ height: "60px" }}
                    />
                  ) : (
                    <button
                      onClick={() => setOpenCamera(true)}
                      disabled={verifiedSteps.profileImage}
                      className={
                        verifiedSteps.profileImage ? "button-verified" : ""
                      }
                    >
                      Camera
                    </button>
                  )}
                </div>
              </div>
            )}

            {currentStep >= 6 && (
              <div className="general_form_field">
                <label>Address:</label>
                <div className="input_button_container">
                  <textarea
                    disabled={verifiedSteps.address}
                    required
                    className={verifiedSteps.address ? "input-disabled" : ""}
                  ></textarea>
                </div>
              </div>
            )}

            {currentStep >= 6 && (
              <div className="general_form_field">
                <label>
                  Upload Utility Bill{" "}
                  <span className="label_span">
                    (It must have same address with what your Provide)
                  </span>
                </label>
                <div className="input_button_container">
                  <input
                    type="file"
                    disabled={verifiedSteps.address}
                    required
                    className={verifiedSteps.address ? "input-disabled" : ""}
                    onChange={handleUtilityUpload}
                  />
                  {utilitySrc && <img src={utilitySrc} alt="utility bill" style={{ height: "50px" }} />}
                </div>
              </div>
            )}

            {currentStep >= 6 && (
              <button className="kyc_submit_btn" onClick={handleSubmit}>Submit</button>
            )}
          </div>
        </div>
      </div>

      {openCamera && (
        <CameraField setImageUrl={setImageUrl} onCapture={handleCapture} />
      )}

      {submit && <SuccessfullModal />}

      <FieldVerifyModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onOtpChange={handleOtpChange}
        otp={otp}
        onConfirmOtp={handleConfirmOtp}
        onContinue={handleContinue}
        isOtpEntered={isOtpEntered}
        setIsOtpEntered={setIsOtpEntered}
        fieldToVerify={fieldToVerify}
      />
    </div>
  );
};

export default KycVerification;

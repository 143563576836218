import React, { useState } from "react";
import { useLocalState } from "../../utils/useLocalStorage";
import "./userBalance.css";
import TotalMoney from "../TotalMoney/TotalMoney";
import { IoIosArrowRoundForward } from "react-icons/io";
import LeverPayLogo from "../../assets/images/leverpay-logo-icon.png";
// import PinkVector3 from "../../assets/images/Vector-blop1.png";

const UserBalance = () => {
  const [amtVisible, setAmtVisible] = useState(false);
  const [jwt, setJwt] = useLocalState("", "jwt");
  const userJson = localStorage.getItem("user");
  const userData = JSON.parse(userJson);

  function viewamtToggle() {
    setAmtVisible(!amtVisible);
  }

  //   const weeklyLimit = 4000;
  //   const currentSpending = userData ? userData.total_spending.ngn / 100 : 0; // Assuming this is in dollars
  //   const spendingLeft = weeklyLimit - currentSpending;
  //     const progressPercentage = (spendingLeft / weeklyLimit) * 100;
  const weeklyLimit = 1000; // Assuming the weekly limit is $4000
  const spentAmount = userData ? userData.total_spending.ngn / 1575.58 : 0; // Assuming conversion rate

  const progressPercentage = (spentAmount / weeklyLimit) * 100;
  const progressBarStyle = {
    width: `${progressPercentage > 100 ? 100 : progressPercentage}%`,
    backgroundColor: "#F49B09EB",
  };

  return (
    <div className="balance-container">
      <div className="user-balance-container">
        <img src={LeverPayLogo} alt="" className="lever-img" />
        <div className="total-balance">
          <h2>Your Balance</h2>
          <TotalMoney
            amt={userData ? userData.wallet.amount.ngn : ""}
            denote="NGN"
            amtVisible={amtVisible}
            denoteStyle="ngn-style"
            amtStyle="amount-style"
            dollarStyle="dollar-style"
            showDollar={true}
          />
          <div className="get-dollar-info">
            <div>Get Dollar Card</div>
            <p>
              Cash Back{" "}
              <span>
                <IoIosArrowRoundForward size={20} />
              </span>
            </p>
          </div>
        </div>
        {/* <img src={PinkVector3} alt="blop here" className="vector-blop4" /> */}

        <div className="current-balance">
          {/* <div className="current-spending">
            <TotalMoney
              amt={userData ? userData.total_spending.ngn : ""}
              denote="N"
              amtVisible={amtVisible}
              denoteStyle="ngn-style2"
              amtStyle="amount-style2"
              showDollar={false}
              className="card-balance-flex"
            />
            <p>Current balance</p>
          </div> */}
          <div className="current-cashback">
            <TotalMoney
              amt={userData ? userData.total_cash_back.ngn : ""}
              denote="N"
              amtVisible={amtVisible}
              denoteStyle="ngn-style3"
              amtStyle="amount-style3"
              showDollar={false}
              className="card-balance-flex"
            />
            <p>Cash back</p>
          </div>
        </div>
      </div>
      <div className="user-progress-container">
        <div className="progress-content">
          <div className="progress-bar-background">
            <div
              className="progress-bar-content"
              style={progressBarStyle}
            ></div>
          </div>
          <div className="progress-details">
            <p>Weekly spending limit</p>
            <p>
              {spentAmount >= weeklyLimit
                ? `$${weeklyLimit}/$${weeklyLimit}`
                : `$${spentAmount.toFixed(2)}/$${weeklyLimit}`}
            </p>
          </div>
        </div>

        <div className="toggle-account">
          <label className="switch">
            <input
              type="checkbox"
              checked={amtVisible}
              onChange={viewamtToggle} // Toggle visibility on checkbox change
            />
            <span className="slider"></span>
          </label>
          {/* <button onClick={viewamtToggle}>
                {amtVisible ? "Hide " : "Show "}
            </button> */}
          <p>{amtVisible ? "Hide Balance " : "Show Balance"}</p>
        </div>
      </div>
    </div>
  );
};

export default UserBalance;

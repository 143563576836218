import "./VerificationModal.css";

import { useNavigate  } from "react-router-dom";

const VerificationModal = ({ onClose }) => {

  const navigate = useNavigate()

  const handleContinue = () => {
    // Navigate to the KYC verification route
    onClose()
    navigate("/kyc-verification");
  };

  const handleEndProcess = () => {
    // Close the modal
    onClose();
  };

  return (
    <div id="kyc_verification_modal_backdrop">
      <div className="kyc_verification_modal_wrapper">
        <p>
          For you to have a Dollar Card, you have to complete a KYC Verification
          Process
        </p>

        <div className="kyc_verification_confirm_btns">
          <button className="proceed_verification_btn" onClick={handleContinue}>
            Proceed
          </button>
          <button
            className="cancel_verification_btn"
            onClick={handleEndProcess}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationModal;

import { Link } from "react-router-dom";
import logo from "../../assets/LeverpayLogo.png";
import avatar from "../../assets/images/avatar.png";

import { FaAngleDown } from "react-icons/fa6";

import "../TopNav/TopNav.css";

const TopNav = ({ userName }) => {
  console.log(userName);

  // Get the initial of the last name
  const lastInitial =
    userName.lastName && userName.lastName.charAt(0).toUpperCase() + ".";

  return (
    <header className="dashboard_header_content_wrapper">
      <div className="header_logo_profile_wrapper">
        <div className="user_profile_component">
          <div className="straight_line"></div>
          <div className="profile_img_container">
            <img src={avatar} alt="user avatar" />
          </div>
          <p>
            {userName.firstName && userName.firstName} {lastInitial}
          </p>
          <Link to="/settings" className="link_arrow_dw">
            <FaAngleDown className="arrow_dw" />
          </Link>
        </div>
      </div>

      <div className="track_exchange_container">
        
        <p className="exchange_rate">
          <span>Exchange Rate</span> $1 | N 1,640
        </p>
      </div>
    </header>
  );
};

export default TopNav;

import Webcam from "react-webcam";
import "./CameraField.css";
import { useCallback, useRef, useState } from "react";

const CameraField = ({ setImageUrl, onCapture }) => {

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageUrl(imageSrc)
    onCapture(imageSrc);
  }, [webcamRef]);

  return (
    <div className="camera_field_wrapper">
      <Webcam
        width={300}
        height={300}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
      />

      <button className="camera_shutter" onClick={capture}>
        Capture photo
      </button>
    </div>
  );
};

export default CameraField;

import React from "react";
import SignupComponent from "../../components/SignupComponent/SignupComponent";

function SignupPage() {
  return (
    <>
      <SignupComponent />
    </>
  );
}

export default SignupPage;

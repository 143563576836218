import "./Step.css";

const Step = ({ currentStep }) => {
  return (
    <div className="step_wrapper">
      <div className="step_item">
        <span
          className="step_container_out"
        >
          <span className={`step_ball ${currentStep >= 1 ? "white_step_ball" : ""}`}></span>
        </span>
        <span className="step_label">Email</span>
      </div>

      <div className="step_item">
        <span
        className="step_container_out"
        >
          <span className={`step_ball ${currentStep >= 2 ? "white_step_ball" : ""}`}></span>
        </span>
        <span className="step_label">Phone</span>
      </div>

      <div className="step_item">
        <span
        className="step_container_out"
        >
          <span className={`step_ball ${currentStep >= 3 ? "white_step_ball" : ""}`}></span>
        </span>
        <span className="step_label">Bvn</span>
      </div>

      <div className="step_item">
        <span
        className="step_container_out"
        >
          <span className={`step_ball ${currentStep >= 4 ? "white_step_ball" : ""}`}></span>
        </span>
        <span className="step_label">NIN</span>
      </div>

      <div className="step_item">
        <span
        className="step_container_out"
        >
          <span className={`step_ball ${currentStep >= 5 ? "white_step_ball" : ""}`}></span>
        </span>
        <span className="step_label">Image</span>
      </div>

      <div className="step_item">
        <span
        className="step_container_out"
        >
          <span className={`step_ball ${currentStep >= 6 ? "white_step_ball" : ""}`}></span>
        </span>
        <span className="step_label">Address</span>
      </div>
    </div>
  );
};

export default Step;

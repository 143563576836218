import React from "react";
import TransferInterface from "../../components/TransferPageComponent/TransferInterface";

const TransferPage = ({ naira_code, dollar_code }) => {
  return (
    <>
      <TransferInterface />
    </>
  );
};

export default TransferPage;

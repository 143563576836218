import React from 'react'
import ReceiptInPDF from "../../components/ReusableComponents/Receipt/ReceiptInPDF";

export default function Pdf() {
  return (
  <>
    <ReceiptInPDF />
  </>
  )
}

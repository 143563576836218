import { useState } from "react";
import "./VirtualCard.css";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { TbLockFilled } from "react-icons/tb";
import { IoMdShare } from "react-icons/io";
import { IoCopy } from "react-icons/io5";
import MasterCard from "../../assets/images/mastercard-logo.png";
import { IoIosArrowBack } from "react-icons/io";

const VirtualCard = () => {
  const [showCardPin, setShowCardPin] = useState(false);

  const togglePin = () => {
    setShowCardPin(!showCardPin);
  };

  return (
    <div className="virtual-card-holder">
      <div className="virtual-card-details">Card Details</div>
      <div className="virtual-container">
        <div className="all-card-container">
          <div className="virtual-card-header">
            <IoIosArrowBack size={20} />
            <span>My Card Details</span>
          </div>
          <div className="virtual-card">
            <div className="card-top-details">
              <h3>CITI BANK</h3>
              <div>
                User Card{" "}
                <span>
                  <img src={MasterCard} alt="" />
                </span>
              </div>
            </div>
            <div className="card-visibility" onClick={togglePin}>
              {showCardPin ? (
                <IoEyeOutline size={20} />
              ) : (
                <IoEyeOffOutline size={20} />
              )}
            </div>
            <div className="card-pin">
              {showCardPin ? "1284 5467 9834 0973" : "xxx xxxx xxxx xxxx"}{" "}
            </div>
            <div className="card-validity">
              <p className="card-date">
                Valid Thru
                <br />
                <span>04/26</span>
              </p>
              <p className="card-cvv">
                CVV: <span>{showCardPin ? "233" : "xxx"}</span>
              </p>
            </div>
            <div className="card-name">Patrick O. Claudius</div>
          </div>
          <div className="virtual-card-information">
            <p>
              Correspondent Bank: <span>CITIBANK, NEW YORK</span>
            </p>
            <p>
              Swift Code: <span>CITIUS33</span>
            </p>
            <p>
              Sort code: <span>N/A</span>
            </p>
            <p>
              Fedwire/aba no: <span>021000089</span>
            </p>
            <p>
              For credit of: <span>GUARANTY TRUST BANK PLC, LAGOS NIGERIA</span>
            </p>
            <p>
              GTBank swift code: <span>GTBINGLA</span>
            </p>
            <p>
              GTBank account number with corresponding bank:
              <span>36129295</span>
            </p>
            <p>
              Beneficiary name: <span>CLAUDIUS PATRICK OLUWADAMILARE</span>
            </p>
            <p>
              Beneficiary Account Number: <span>0732753509</span>
            </p>
            <div className="virtual-tools">
              <IoMdShare size={20} />
              <IoCopy size={20} />
            </div>
          </div>
        </div>
      </div>
      <div className="virtual-card-footer">
        <span>
          <TbLockFilled
            size={20}
            style={{ color: "white", marginTop: "-7px" }}
          />
        </span>
        Secured By LeverChain Technology
      </div>
    </div>
  );
};
export default VirtualCard;

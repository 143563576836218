import React from 'react'
import AirtimePayment from '../../../components/PayBillsCompontent/AirtimeCompontent/AirtimeComponentPin'

export default function AirtimePaymentPage() {
  return (
  <>
    <AirtimePayment />
  </>
  )
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocalState } from "../../utils/useLocalStorage";
import { Link } from "react-router-dom";
import "./AllTransactions.css";
import SearchBar from "../../components/SearchBar/SearchBar";

const RecentTransactions = () => {
  const [jwt, setJwt] = useLocalState("", "jwt");
  const [transactions, setTransactions] = useState([]);
  const [isData, setIsData] = useState(false);

  const baseurl =
    "https://leverpay-api.azurewebsites.net/api/v1/user/get-user-transactions";

  useEffect(() => {
    axios
      .get(baseurl, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        const recentTransactions = res.data.data.slice(0, 5);
        console.log(res.data);
        setTransactions(recentTransactions);
        setIsData(recentTransactions.length > 0);
      })
      .catch((error) => {
        console.log(error);
        setIsData(false);
      });
  }, [jwt]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <div className="recent">
      <header>
        <h1>Recent Transactions</h1>
        <div className="SearchBar" style={{ display: "flex" }}>
          <SearchBar />
          <button>
            <Link to="/transactions">All</Link>
          </button>
        </div>
      </header>

      <main>
        <ul className="allTransactions-head">
          <li>Receiver</li>
          <li>Type</li>
          <li>Date</li>
          <li>Amount</li>
        </ul>
        {isData ? (
          transactions.map((item) => (
            <ul className="allTransactionsBody" key={item.created_at}>
              <li>{item.merchant}</li>
              <li>{item.type}</li>
              {/* <li
                style={{
                  color:
                    item.status === 0
                      ? "#F79E1B"
                      : item.status === 1
                      ? "#329521"
                      : "red",
                }} 
              >
                {item.status === 0
                  ? "Pending"
                  : item.status === 1
                  ? "Successful"
                  : "Failed"}
              </li> */}
              <li style={{ color: "#AEAEAE" }}>
                {formatDate(item.created_at)}
              </li>
              <li style={{ fontWeight: 500 }}>
                N {Number(item.amount).toFixed(2)}
              </li>
            </ul>
          ))
        ) : (
          <h3>No Transactions</h3>
        )}
      </main>
    </div>
  );
};

export default RecentTransactions;

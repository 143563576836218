const CardInfo = [
    {
        id: 'Gold',
        name: 'name',
        no: 1234567898703467,
        cvv: 123
    },
    {
        id: 'Diamond',
        name: 'name',
        no: 1234567898703467,
        cvv: 123
    },
    {
        id: 'Pink Lady',
        name: 'name',
        no: 1234567898703467,
        cvv: 123
    },
    {
        id: 'Enterprise',
        name: 'name',
        no: 1234567898703467,
        cvv: 123
    },
    {
        id: 'Silver',
        name: 'name',
        no: 1234567898703467,
        cvv: 123
    },
    {
        id: 'Default',
        name: 'name',
        no: 1234567898703467,
        cvv: 123
    }
]

export default CardInfo
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import style from "./ElectricityComponent.module.css"; // Choose the correct path
import { useLocalState } from "../../../utils/useLocalStorage";
import { validateCustomer } from "../../../services/apiService";

const providerDetails = {
  "ABJPM": { id: 33948, PaydirectItemCode: 8219 },
  "Benin Electricity Distribution Company": {
    postpaid: { id: 646, PaydirectItemCode: 5161 },
    prepaid: { id: 767, PaydirectItemCode: 5606 }
  },
  "Bonny Utility": { id: 3780, PaydirectItemCode: 8014 },
  "EKEDC": { id: 246, PaydirectItemCode: 5081 },
  "EKEDP": { id: 1473, PaydirectItemCode: 5081 },
  "EKO ELECTRIC": {
    postpaid: { id: 33964, PaydirectItemCode: 8223 }
  },
  "Enugu Electricity Distribution Company": {
    postpaid: { id: 785, PaydirectItemCode: 4103 },
    prepaid: { id: 783, PaydirectItemCode: 4122 }
  },
  "EEDC": {
    prepaid: { id: 33951, PaydirectItemCode: 8220 }
  },
  "IBEDC": {
    postpaid: { id: 34134, PaydirectItemCode: 8323 },
    prepaid: { id: 34135, PaydirectItemCode: 8323 }
  },
  "Ikeja Electric": {
    postpaid: { id: 848, PaydirectItemCode: 5640 },
    prepaid: { id: 766, PaydirectItemCode: 5640 }
  },
  "JEDC": { id: 2978, PaydirectItemCode: 7069 },
  "JOS ELECTRIC": {
    prepaid: { id: 33961, PaydirectItemCode: 8221 }
  },
  "KADUNA ELECTRIC": {
    prepaid: { id: 33945, PaydirectItemCode: 8218 }
  },
  "Kaduna Electricity Distribution Company": {
    postpaid: { id: 2970, PaydirectItemCode: 6183 },
    prepaid: { id: 2969, PaydirectItemCode: 6183 }
  },
  "PHCN": { id: 103, PaydirectItemCode: 2 },
  "PHED": {
    prepaid: { id: 3712, PaydirectItemCode: 4825 },
    prepaidII: { id: 33944, PaydirectItemCode: 8217 }
  },
  "YOLA ELECTRIC": {
    prepaid: { id: 34063, PaydirectItemCode: 8280 }
  },
  "Yola Electricity Distribution Company": {
    postpaid: { id: 1753, PaydirectItemCode: 5253 }
  }
};

const ElectricityComponent = () => {
  const [providers, setProviders] = useState([]);
  const [amountFocused, setAmountFocused] = useState(false);
  const [amount, setAmount] = useState("");
  const [provider, setProvider] = useState(null);
  const [smartCardNumber, setSmartCardNumber] = useState("");
  const [smartCardNumberFocused, setSmartCardNumberFocused] = useState(false);
  const [packageOption, setPackageOption] = useState("");
  const [availablePackages, setAvailablePackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [providerError, setProviderError] = useState("");
  const [smartCardError, setSmartCardError] = useState("");
  const [packageError, setPackageError] = useState("");
  const [amountErrorMessage, setAmountErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [customerDetails, setCustomerDetails] = useState(null);
  const [user] = useLocalState("", "user");
  const [jwt] = useLocalState("", "jwt");

  const navigate = useNavigate();

  useEffect(() => {
    const formattedProviders = Object.keys(providerDetails).map((key) => ({
      Name: key,
      Id: providerDetails[key].id,
    }));
    setProviders(formattedProviders);
  }, []);

  const handleProviderChange = (e) => {
    const selectedProviderName = e.target.value;
    const selectedProvider = providers.find(
      (p) => p.Name === selectedProviderName
    );
    setProvider(selectedProvider);

    const providerData = providerDetails[selectedProviderName];
    if (providerData) {
      const packages = Object.keys(providerData)
        .filter((key) => key !== "id")
        .map((key) => ({
          name: key,
          id: providerData[key].id,
          PaydirectItemCode: providerData[key].PaydirectItemCode,
        }));
      setAvailablePackages(packages);
      setPackageOption(""); // Reset package option
      setSelectedPackage(null); // Reset selected package
    } else {
      setAvailablePackages([]);
    }

    setValidationError("");
  };

  const handlePackageOptionChange = (e) => {
    const selectedPackageName = e.target.value;
    const selectedPackage = availablePackages.find(
      (pkg) => pkg.name === selectedPackageName
    );
    setPackageOption(selectedPackageName);
    setSelectedPackage(selectedPackage);

    if (selectedPackage) {
      // Assuming `Amount` is provided in selectedPackage or calculated elsewhere
      if (selectedPackage.Amount > user.wallet.withdrawable_amount.ngn) {
        setPackageError("Insufficient balance, please fund your account");
        return;
      } else {
        setPackageError("");
      }
    }
  };

  const handleSmartCardNumberChange = async (e) => {
    const smartCard = e.target.value;
    setSmartCardNumber(smartCard);

    if (smartCard.length >= 10 && provider && selectedPackage) {
      setIsValidating(true);
      setValidationError("");
      try {
        const validationData = await validateCustomer(
          smartCard,
          selectedPackage.PaydirectItemCode,
          jwt
        );

        console.log("Validation Data:", validationData);

        setCustomerDetails(validationData.Customers[0]);
        setValidationError("");
      } catch (error) {
        console.error("Error during validation:", error);
        setValidationError("Failed to validate customer. Please check the Meter Number");
      } finally {
        setIsValidating(false);
      }
    }
  };

  const handleAmountChange = (e) => {
    const newAmount = e.target.value.replace(/\D/g, "");
    setAmount(newAmount);
    setAmountErrorMessage("");
  };

  const handleSubmit = () => {
    if (!provider || !smartCardNumber || !selectedPackage || !customerDetails) {
      setProviderError(!provider ? "Please select a provider" : "");
      setSmartCardError(!smartCardNumber ? "Please enter a meter number" : "");
      setPackageError(!selectedPackage ? "Please select a package" : "");
      setValidationError(!customerDetails ? "Please validate the Meter Number" : "");
      return;
    }

    if (selectedPackage.Amount > user.wallet.withdrawable_amount.ngn) {
      setPackageError("Insufficient balance, please fund your account");
      return;
    }

    const customerEmail = localStorage.getItem("userEmail");
    const customerMobile = localStorage.getItem("userPhoneNumber");

    const billerData = {
      billerName: provider.Name,
      itemName: selectedPackage.Name,
      itemCode: selectedPackage.PaydirectItemCode,
      customerId: smartCardNumber,
      amount: `${amount}`,
      customerEmail,
      customerMobile,
      smartCardNumber,
      providerId: provider.Id,
      providerName: provider.Name,
      customerName: customerDetails?.FullName || customerDetails?.CustomerName,
      packageName: selectedPackage.name,
      packageOption: selectedPackage.id,
    };

    console.log("Biller Data:", JSON.stringify(billerData));

    localStorage.setItem("billerData", JSON.stringify(billerData));
    navigate("/cable-tv-payment");
  };

  return (
    <div className={style.mainDiv}>
      <div className={style.header}>
        <FaChevronLeft
          className={style.cancelIcon}
          onClick={() => navigate(-1)}
        />
        <h2 className={style.modalTitle}>Electricity</h2>
      </div>

      <div className={style.backup}>
        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Provider</h1>
          <div className={style.dropdown}>
            <select
              value={provider?.Name || ""}
              onChange={handleProviderChange}
              className={style.select}
            >
              <option value="">Select Provider</option>
              {providers.map((p) => (
                <option key={p.Name} value={p.Name}>
                  {p.Name}
                </option>
              ))}
            </select>
          </div>
          {providerError && <p className={style.error}>{providerError}</p>}
        </div>

        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Package</h1>
          <div className={style.dropdown}>
            <select
              value={packageOption}
              onChange={handlePackageOptionChange}
              className={style.select}
              disabled={!provider}
            >
              <option value="">Select Package</option>
              {availablePackages.map((pkg) => (
                <option key={pkg.name} value={pkg.name}>
                  {pkg.name}
                </option>
              ))}
            </select>
          </div>
          {packageError && <p className={style.error}>{packageError}</p>}
        </div>

        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Meter Number</h1>
          <input
            type="text"
            value={smartCardNumber}
            onChange={handleSmartCardNumberChange}
            onFocus={() => setSmartCardNumberFocused(true)}
            onBlur={() => setSmartCardNumberFocused(false)}
            className={`${style.input} ${smartCardNumberFocused ? style.focusedInput : ""}`}
            placeholder="Meter Number"
          />
          {smartCardError && <p className={style.errorMessage}>{smartCardError}</p>}
          {validationError && <p className={style.errorMessage}>{validationError}</p>}
        </div>

        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Amount</h1>
          <input
            type="text"
            value={amount}
            onChange={handleAmountChange}
            onFocus={() => setAmountFocused(true)}
            onBlur={() => setAmountFocused(false)}
            className={`${style.input} ${amountFocused ? style.focusedInput : ""}`}
            placeholder="Amount"
          />
          {amountErrorMessage && <p className={style.error}>{amountErrorMessage}</p>}
        </div>

       
<div className={style.buttonGroup}>
          <button
            type="button"
            className={style.buttonSubmit}
            onClick={handleSubmit}
          >
            Proceed
          </button>
          </div>
      </div>
    </div>
  );
};

export default ElectricityComponent;

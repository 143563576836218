import React from "react";
import DataComponent from "../../../components/PayBillsCompontent/DataCompontent/DataComponent";

const DataPage = () => {
  return (
    <div>
      <DataComponent />
    </div>
  );
};

export default DataPage;

import "./BillerComponent.css";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import { FaWifi } from "react-icons/fa6";
import { RiLightbulbFlashLine } from "react-icons/ri";
import Cable from "../../assets/images/cable-img.png";
import Invoice from "../../assets/images/invoive-img.png";
import Investment from "../../assets/images/investment-img.png";
import Transport from "../../assets/images/transport-img.png";
import More from "../../assets/images/more-img.png";
import { AiOutlineGlobal } from "react-icons/ai";
import { PiTicket } from "react-icons/pi";
import { BsFillSendFill } from "react-icons/bs";
import { RiExchangeFundsLine } from "react-icons/ri";
import { LiaExchangeAltSolid } from "react-icons/lia";

const BillerComponent = () => {
  return (
    <>
      <div className="funds-side-container">
        <div className="funds-side-icon-container">
          <Link to="/funding">
            <div className="fund-side-text-container">
              <div className="side-icon-container">
                <RiExchangeFundsLine size={20} style={{ color: "#0F3FB2" }} />
              </div>
              <p>Fund Account</p>
            </div>
          </Link>
          <Link to="/transfer">
            <div className="fund-side-text-container">
              <div className="side-icon-container">
                <BsFillSendFill size={20} style={{ color: "#0F3FB2" }} />
              </div>
              <p>Transfer Money</p>
            </div>
          </Link>
          <Link to="/transactions">
            <div className="fund-side-text-container">
              <div className="side-icon-container">
                <LiaExchangeAltSolid size={20} style={{ color: "#0F3FB2" }} />
              </div>
              <p>Transaction History</p>
            </div>
          </Link>
          <Link to="/more">
            <div className="fund-side-text-container">
              <div className="side-icon-container">
                <img src={More} alt="" />
              </div>
              <p>More</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="biller-component-container">
        <div className="biller-icon-container">
          <Link to="/airtime">
            <div className="icon-text-container">
              <div className="icon-container yellow">
                <IoMdCall size={30} style={{ color: "black" }} />
              </div>
              <p>Airtime</p>
            </div>
          </Link>

          <Link to="/data">
            <div className="icon-text-container">
              <div className="icon-container white">
                <FaWifi size={30} style={{ color: "#329521" }} />
              </div>
              <p>Data</p>
            </div>
          </Link>
          <Link to="/electricity">
            <div className="icon-text-container">
              <div className="icon-container red">
                <RiLightbulbFlashLine size={30} style={{ color: "white" }} />
              </div>
              <p>Electricity</p>
            </div>
          </Link>

          <Link to="/cable-tv">
            <div className="icon-text-container">
              <div className="icon-container blue">
                <img src={Cable} alt="" />
              </div>
              <p>Cable</p>
            </div>
          </Link>
          {/* COMING SOON */}
          {/* <Link to="/internet"> */}
          <div className="icon-text-container disabled">
            <div className="icon-container white">
              <AiOutlineGlobal size={30} style={{ color: "black" }} />
            </div>
            <p>Internet</p>
            {/* <small className="coming-soon-label">Coming Soon</small> */}
          </div>
          {/* </Link> */}
          <Link to="/invoices">
            <div className="icon-text-container">
              <div className="icon-container green">
                <img src={Invoice} alt="" />
              </div>
              <p>Invoice</p>
            </div>
          </Link>
          {/* COMING SOON */}
          {/* <Link to="/ticket"> */}
          <div className="icon-text-container disabled">
            <div className="icon-container white">
              <PiTicket size={30} style={{ color: "#d24fdb" }} />
            </div>
            <p>Ticket</p>
          </div>
          {/* </Link> */}
          {/* COMING SOON */}
          {/* <Link to="/investment"> */}
          <div className="icon-text-container disabled">
            <div className="icon-container green">
              <img src={Investment} alt="" />
            </div>
            <p>Investment</p>
          </div>
          {/* </Link> */}
          {/* COMING SOON */}
          {/* <Link to="/transport"> */}
          <div className="icon-text-container disabled">
            <div className="icon-container white">
              <img src={Transport} alt="" />
            </div>
            <p>Transport</p>
          </div>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};
export default BillerComponent;

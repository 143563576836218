import React from 'react'
import AllFundingHistory from './AllFundingHistory'
import './AllTransactions.css'


const AllFundingHistoryCon = () => {
  return (
    <div className='allTransactionCon'>
        <AllFundingHistory/>
    </div>
  )
}

export default AllFundingHistoryCon
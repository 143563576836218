import React from "react";
import ElectricityComponent from "../../../components/PayBillsCompontent/ElectricityComponent/ElectricityComponent";

// import RecieptInPDF from "../../../components/PayBillsCompontent/CableTvCompontent/ReceiptInPDF";
import SuccessfulScreenWithDownloadButton from "../../../components/ReusableComponents/LoadingPage/SuccessfulScreenWithDownloadButton"

const ElectricityPage = () => {
  return (
    <div>
      {/* <SuccessfulScreenWithDownloadButton />  */}
      {/* <ReceiptInImage />
      {/* < RecieptInPDF /> */}
      <ElectricityComponent />
    </div>
  );
};

export default ElectricityPage;

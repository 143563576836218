import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import style from "./Receipt.module.css";
import { useNavigate } from "react-router-dom";
import LeverpayIcon from "../../../assets/LeverpayLogo_Blue.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; 

export function ReceiptInImage() {
  const [billerData, setBillerData] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("Failed");
  const [transactionTime, setTransactionTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedBillerData = JSON.parse(localStorage.getItem("billerData"));
    const storedPaymentStatus = localStorage.getItem("paymentStatus");

    if (storedBillerData) {
      setBillerData(storedBillerData);
    } else {
      navigate(-1);
    }

    if (storedPaymentStatus) {
      setPaymentStatus(storedPaymentStatus);
    }

    const now = new Date();
    const formattedTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
    setTransactionTime(formattedTime);
  }, [navigate]);

  const generateImage = () => {
    const input = document.getElementById("mainDiv");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "receipt.png";
      link.click();
    });
  };

  const handleCancel = () => {
    navigate("/paybills");
  };

  const paymentStatusClass = paymentStatus === "Successful" ? style.successfulStatus : style.failedStatus;

  return (
    <>
      <div className={style.cancelIconDiv}>
        <FontAwesomeIcon icon={faTimes} className={style.cancelIcon} onClick={handleCancel} />
      </div>
      <div id="mainDiv" className={style.mainDiv}>
        <div className={style.logoDiv}>
          <div className={style.logo}>
            <img src={LeverpayIcon} alt="Leverpay Logo" />
            <p className={style.transaction}>Transaction Receipt</p>
          </div>

          <div className={style.innerLogoDiv}>
            <p className={style.transaction}>NGN {billerData?.amount}.00</p>
            <p className={style.transaction}>{paymentStatus}</p>
            <p className={style.time}>{transactionTime}</p>
          </div>
        </div>
        <div className={style.tableLine}></div>

        <div className={style.backup}>
          <div className={style.formGroup}>
            <table className={style.infoTable}>
              <tbody>
                <tr>
                  <td className={style.groupP}>Provider:</td>
                  <td className={style.billerData}>{billerData?.providerName}</td>
                </tr>
                <tr>
                  <td className={style.groupP}>Package:</td>
                  <td className={style.billerData}>{billerData?.packageName}</td>
                </tr>
                <tr>
                  <td className={style.groupP}>Customer Name:</td>
                  <td className={style.billerData}>{billerData?.customerName}</td>
                </tr>
                <tr>
                  <td className={style.groupP}>Email:</td>
                  <td className={style.billerData}>{billerData?.customerEmail}</td>
                </tr>
                <tr>
                  <td className={style.groupP}>Smart Card Number:</td>
                  <td className={style.billerData}>
                    {billerData?.smartCardNumber}
                  </td>
                </tr>
                <tr>
                  <td className={style.groupP}>Payment status:</td>
                  <td>
                    <p className={`${style.paymentStatus} ${paymentStatusClass}`}>
                      {paymentStatus}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={style.tableLine}></div>
          </div>
          <div className={style.supportDiv}>
            <p className={style.support}>Support</p>
            <p className={style.support2}>contact@leverpay.io</p>
          </div>

          <div className={style.tableLine}></div>
          <div className={style.supportDiv3}>
            <p className={style.support3}>
              Enjoy More Bills Payment with Leverpay. Instant transfer, and more.
              <span className={style.supportSpan}>
              Leverpay (Leverchain) is a FINTECH company in partership with Banks Licensed by the CBN.
              </span>
            </p>
          </div>
        </div>
      </div>
      
      <div className={style.buttonDiv}>
        <button className={style.downloadButton} onClick={generateImage}>
          Download Receipt as Image
        </button>
      </div>
    </>
  );
}

export default ReceiptInImage;

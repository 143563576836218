import React from 'react'
import CableTvPaymentScreenComponent from '../../../components/PayBillsCompontent/CableTvCompontent/CableTvPaymentScreenComponent'


export default function CableTvPaymentScreen() {
  return (
   <>
   <CableTvPaymentScreenComponent/>
   </>
  )
}

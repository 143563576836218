import React from 'react';
import AirtimeComponent from '../../../components/PayBillsCompontent/AirtimeCompontent/AirtimeCompontent';

const AirtimePage = () => {
  return (
    <div>
      <AirtimeComponent />
    </div>
  );
}

export default AirtimePage;

import React from 'react'
import PinPageComponent from "../../components/EnterPinScreen/EnterPinScreen"

export default function PinPage() {
  return (
   <>
   <PinPageComponent />
   </>
  )
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocalState } from "../../utils/useLocalStorage";
import "./totalMoney.css";

function TotalMoney(props) {
  const {
    className,
    totaltype,
    amt,
    denote,
    amtVisible,
    denoteStyle,
    amtStyle,
    dollarStyle,
    showDollar,
  } = props;
  const [rate, setRate] = useState();
  const [jwt, setJwt] = useLocalState("", "jwt");

  useEffect(() => {
    axios
      .get(
        "https://leverpay-api.azurewebsites.net/api/v1/user/get-exchange-rates",
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((res) => {
        setRate(res.data.data);
        // console.log("Exchange data:", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatToTwoDecimalPlaces = (amount) => {
    return Math.trunc(amount * 100) / 100;
  };

  const truncatedAmt = formatToTwoDecimalPlaces(amt).toFixed(2);

  const dollar = rate ? "$" + (amt / rate.rate).toFixed(2) : "";

  return (
    <div className={`${className} card-balance`}>
      <p>{totaltype}</p>
      <strong>
        {amtVisible ? (
          <div>
            <span className={denoteStyle}>{denote} </span>
            <span className={amtStyle}>{truncatedAmt}</span>
          </div>
        ) : (
          "XXXX"
        )}
      </strong>
      {showDollar && (
        <div className={dollarStyle}>{amtVisible ? dollar : "xxx"}</div>
      )}
    </div>
  );
}

export default TotalMoney;

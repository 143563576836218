import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/esm/Container";
import Cardcategory from "../CardCategoryPage/CardCategory/Cardcategory";

function CardcategoryPage() {
  return (
    <Container>
      <Cardcategory />
    </Container>
  );
}

export default CardcategoryPage;

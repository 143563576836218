import React from "react";
import MySubscriptionComponent from "../../components/MySubscriptionComponent/MySubscriptionComponent";

const MySubscription = ({ payNow }) => {
  // console.log(payNow);
  return (
    <div>
      <MySubscriptionComponent />
    </div>
  );
};

export default MySubscription;

import React, { useState } from "react";
// import "./TrialNavComp.css";

import { IoIosArrowForward } from "react-icons/io";
import { RiCloseFill } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import { GrPowerCycle } from "react-icons/gr";
import { FaMoneyBills } from "react-icons/fa6";
import { BsSend } from "react-icons/bs";
import { PiUserSwitch } from "react-icons/pi";
import { TbReceipt2 } from "react-icons/tb";
import { HiOutlineCreditCard } from "react-icons/hi";
import { CiSettings } from "react-icons/ci";
import { MdFace6 } from "react-icons/md";
import { LuHelpCircle } from "react-icons/lu";
import { RiLogoutCircleRLine } from "react-icons/ri";

import new_dashboard from "../../assets/images/new_dashboard.svg";
import logo from "../../assets/LeverpayLogo.png";
import { logoutUser } from "../../services/apiService";
import "../NavComponent/NavComponent.css";

const NavComponent = ({ openKycModal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logoutUser();
  };

  const handleLinkClick = () => {
    if (isOpen) {
      toggleNav();
    }
  };

  const handleCardClick = () => {
    handleLinkClick();
    openKycModal();
  };

  return (
    <>
      <div className={`dashboard_side-nav ${isOpen ? "open" : ""}`}>
        {/* Mobile side navigation toggler */}
        <button className="dashboard_mobile-toggle-btn" onClick={toggleNav}>
          {isOpen ? (
            <RiCloseFill className="dashboard_mobile-toggle-icon" />
          ) : (
            <IoIosArrowForward className="dashboard_mobile-toggle-icon" />
          )}
        </button>

        <div className="menu_heading">
          <img src={logo} alt="logo" />
        </div>

        <div className="dashboard_page_link_container">
          <Link
            to="/"
            className="dashboard_page_link_content"
            onClick={handleLinkClick}
          >
            <img src={new_dashboard} alt="dashboard logo" />
            Dashboard
          </Link>
        </div>

        <nav className="dashboard_pages_navigations">
          <NavLink
            to="/funding/naira-deposit"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <GrPowerCycle className="page_nav_link_icon" />
            Funding
          </NavLink>
          <NavLink
            to="/paybills"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <FaMoneyBills className="page_nav_link_icon" />
            Bill Payment
          </NavLink>
          <NavLink
            to="/transfer"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <BsSend className="page_nav_link_icon" />
            Transfer
          </NavLink>
          <button
            to="/my-cards"
            className="page_nav_link_content card_nav_btn"
            onClick={handleCardClick}
          >
            <HiOutlineCreditCard className="page_nav_link_icon" />
            Card
          </button>
          <NavLink
            to="/referral"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <PiUserSwitch className="page_nav_link_icon" />
            Referral
          </NavLink>
          <NavLink
            to="/invoices"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <TbReceipt2 className="page_nav_link_icon" />
            Invoices
          </NavLink>
        </nav>

        <h2 className="general_heading">General</h2>

        <nav className="general_pages_navigations">
          <NavLink
            to="/settings"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <CiSettings className="page_nav_link_icon" />
            Settings
          </NavLink>
          <NavLink
            to="/contact-us"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <MdFace6 className="page_nav_link_icon" />
            Contact Us
          </NavLink>
          <NavLink
            to="/help"
            className="page_nav_link_content"
            onClick={handleLinkClick}
          >
            <LuHelpCircle className="page_nav_link_icon" />
            Help
          </NavLink>

          <button
            onClick={handleLogout}
            to="/logout"
            className="logout_button_wrapper"
          >
            <div className="rounded_log_btn">
              <RiLogoutCircleRLine className="page_nav_link_icon" />
            </div>
            Logout
          </button>
        </nav>
      </div>
    </>
  );
};

export default NavComponent;

import React from "react";
import "./MerchantComponent.css";
import Merchants from "./Merchants/Merchants";
import logo1 from "../../assets/images/credit-card-img.png";
import logo2 from "../../assets/images/reward-img.png";
import { IoIosArrowRoundForward } from "react-icons/io";

export const MerchantComponent = () => {
  return (
    <div className="mCOnn">
      <div className="merchant-container col-md-12">
        <div className="merchants">
          <Merchants logo={logo1}>
            <h4 className="merchant-text">
              Get USD Card to Make international Transaction Today!
            </h4>
            <button className="button-style-one">Get Dollar Card</button>
          </Merchants>
        </div>

        <div className="merchants">
          <Merchants logo={logo2} className="second-container">
            <h4>Refer and Get Rewarded</h4>
            <button className="button-style">
              Use your code <IoIosArrowRoundForward size={20} />
            </button>
          </Merchants>
        </div>
      </div>
    </div>
  );
};

// import React from "react";
// import SearchBar from "../SearchBar/SearchBar";
// import { Link } from "react-router-dom";
// import "./MerchantComponent.css";
// import Merchants from "./Merchants/Merchants";
// import Apple from "../../assets/images/apple.png";
// import Apple2 from "../../assets/images/apple2.png";
// import Nike2 from "../../assets/images/nike2.png";
// import Nike from "../../assets/images/nike.png";
// import Netflix from "../../assets/images/netflix.png";
// import Netflix2 from "../../assets/images/netflix2.png";
// import Amazon from "../../assets/images/amazon.png";
// import Amazon2 from "../../assets/images/amazon2.png";
// export const MerchantComponent = () => {
//   return (
//     <div className="mCOnn">
//       <div className="col-md-12 merchant-header ">
//         <div className="merchant_COn">
//           <div className="col-md-12 merchant-header ">
//             <h3>My Merchants</h3>
//             <span className="merchant_search">
//               <SearchBar />
//             </span>
//             <Link className="details-link up">View All</Link>
//           </div>
//           <div className="merchant-container col-md-12">
//             <div className="shop-more">
//               <h4>Shop Now</h4>
//               <Link className="details-link">More Details</Link>
//             </div>
//             <div className="merchants">
//               {" "}
//               <Merchants logo1={Apple} logo2={Apple2} />
//             </div>
//             <div className="merchants">
//               {" "}
//               <Merchants logo1={Nike2} logo2={Nike} />
//             </div>
//             <div className="merchants">
//               {" "}
//               <Merchants logo1={Netflix} logo2={Netflix2} />
//             </div>
//             <div className="merchants">
//               <Merchants logo1={Amazon} logo2={Amazon2} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

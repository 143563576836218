import React from "react";
import PayBillsComponents from "../../components/PayBillsCompontent/PayBill";

export default function PayBillsPage() {
  return (
    <div>
      <PayBillsComponents />
    </div>
  );
}

import React from 'react'
import DataPaymentComponent from '../../../components/PayBillsCompontent/DataCompontent/DataPaymentComponent'

export default function DataPaymentScreen() {
  return (
    <>
    <DataPaymentComponent/>
    </>
  )
}
